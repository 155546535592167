/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable func-style */
import React from "react"
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import { useTheme } from "../../theme/use-theme";
/* eslint-disable-next-line */
export interface ProgressBarProps {
  loading: boolean;
}

export var ProgressBar = (props: ProgressBarProps) => {
  const { loading } = props;
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: theme.appColors.contentBackground.default,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? "800ms" : "0ms",
        }}
        unmountOnExit
      >
        <CircularProgress size={40} />
      </Fade>
    </Box>
  );
}

export default ProgressBar;
