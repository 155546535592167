import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Any: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  JSON: { input: any; output: any; }
  NullInt64: { input: any; output: any; }
  NullString: { input: string; output: string; }
  NullTime: { input: any; output: any; }
  Time: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type GQLCbexintegrationExam = {
  __typename?: 'CbexintegrationExam';
  ID: Scalars['ID']['output'];
  availableSeats?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<Scalars['NullString']['output']>;
  country?: Maybe<Scalars['NullString']['output']>;
  examDate?: Maybe<Scalars['Time']['output']>;
  houseNumber?: Maybe<Scalars['NullString']['output']>;
  postalCode?: Maybe<Scalars['NullString']['output']>;
  street?: Maybe<Scalars['NullString']['output']>;
  suffix?: Maybe<Scalars['NullString']['output']>;
};

export type GQLCities = {
  __typename?: 'Cities';
  city?: Maybe<Scalars['NullString']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
};

export type GQLExamPeriod = {
  endDate?: InputMaybe<Scalars['Time']['input']>;
  exam?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  method?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Time']['input']>;
};

export type GQLGeoLocation = {
  __typename?: 'GeoLocation';
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

export type GQLGoogleMapsAutoCompleteResult = {
  __typename?: 'GoogleMapsAutoCompleteResult';
  googleSearchResult?: Maybe<Array<GQLGoogleSearchResult>>;
};

export type GQLGoogleSearchResult = {
  __typename?: 'GoogleSearchResult';
  Description?: Maybe<Scalars['String']['output']>;
  PlaceID?: Maybe<Scalars['String']['output']>;
};

export type GQLMutation = {
  __typename?: 'Mutation';
  NewWebshopOrder: GQLWebshopOrder;
};


export type GQLMutationNewWebshopOrderArgs = {
  input: GQLWebshopOrderWithOrderLinesParams;
};

export type GQLQuery = {
  __typename?: 'Query';
  ExamCities?: Maybe<Array<GQLCities>>;
  ExamDataByPeriod?: Maybe<Array<GQLCbexintegrationExam>>;
  ExamDataByPlaceID?: Maybe<Array<GQLCbexintegrationExam>>;
  MapsAutoComplete?: Maybe<GQLGoogleMapsAutoCompleteResult>;
  MapsGeoCoding: GQLGeoLocation;
  WebshopProductByNameType?: Maybe<GQLWebshopProduct>;
  WebshopProducts?: Maybe<Array<GQLWebshopProductList>>;
};


export type GQLQueryExamCitiesArgs = {
  input: GQLExamPeriod;
};


export type GQLQueryExamDataByPeriodArgs = {
  input: GQLExamPeriod;
};


export type GQLQueryExamDataByPlaceIdArgs = {
  examData: GQLExamPeriod;
  placeID: Scalars['String']['input'];
};


export type GQLQueryMapsAutoCompleteArgs = {
  searchName: Scalars['String']['input'];
};


export type GQLQueryMapsGeoCodingArgs = {
  placeID: Scalars['String']['input'];
};


export type GQLQueryWebshopProductByNameTypeArgs = {
  productName: Scalars['String']['input'];
  productType: Scalars['String']['input'];
};


export type GQLQueryWebshopProductsArgs = {
  categoryID?: InputMaybe<Scalars['ID']['input']>;
};

export type GQLWebshopOrder = {
  __typename?: 'WebshopOrder';
  ID?: Maybe<Scalars['Int']['output']>;
  totalPrice?: Maybe<Scalars['Int']['output']>;
};

export type GQLWebshopOrderLineParams = {
  lineID: Scalars['Int']['input'];
  options: Scalars['JSON']['input'];
  orderLineDetails: Scalars['JSON']['input'];
  price: Scalars['Decimal']['input'];
  productID: Scalars['Int']['input'];
  productName: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  tax: Scalars['Decimal']['input'];
};

export type GQLWebshopOrderWithOrderLinesParams = {
  city: Scalars['NullString']['input'];
  contactPerson: Scalars['NullString']['input'];
  emailAddress: Scalars['NullString']['input'];
  houseNumber: Scalars['NullString']['input'];
  note?: InputMaybe<Scalars['NullString']['input']>;
  orderLine: Array<GQLWebshopOrderLineParams>;
  organization: Scalars['NullString']['input'];
  phoneNumber: Scalars['String']['input'];
  postalCode: Scalars['NullString']['input'];
  street: Scalars['NullString']['input'];
  taxAmount?: InputMaybe<Scalars['Decimal']['input']>;
  totalPrice?: InputMaybe<Scalars['Decimal']['input']>;
};

export type GQLWebshopProduct = {
  __typename?: 'WebshopProduct';
  ID: Scalars['ID']['output'];
  Options?: Maybe<Scalars['Any']['output']>;
  commercialText?: Maybe<Scalars['JSON']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['NullString']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Decimal']['output']>;
  tax?: Maybe<Scalars['Decimal']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type GQLWebshopProductList = {
  __typename?: 'WebshopProductList';
  ID: Scalars['ID']['output'];
  Options?: Maybe<Scalars['Any']['output']>;
  categoryID?: Maybe<Scalars['ID']['output']>;
  commercialText?: Maybe<Scalars['JSON']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['NullString']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Decimal']['output']>;
  tax?: Maybe<Scalars['Decimal']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type GQLAddWebshopOrderMutationVariables = Exact<{
  input: GQLWebshopOrderWithOrderLinesParams;
}>;


export type GQLAddWebshopOrderMutation = { __typename?: 'Mutation', NewWebshopOrder: { __typename?: 'WebshopOrder', ID?: number | null, totalPrice?: number | null } };

export type GQLGetCityListQueryVariables = Exact<{
  input: GQLExamPeriod;
}>;


export type GQLGetCityListQuery = { __typename?: 'Query', ExamCities?: Array<{ __typename?: 'Cities', city?: string | null, enabled?: boolean | null }> | null };

export type GQLGetExamDataByPlaceIdQueryVariables = Exact<{
  examData: GQLExamPeriod;
  id: Scalars['String']['input'];
}>;


export type GQLGetExamDataByPlaceIdQuery = { __typename?: 'Query', ExamDataByPlaceID?: Array<{ __typename?: 'CbexintegrationExam', ID: string, examDate?: any | null, availableSeats?: number | null, street?: string | null, houseNumber?: string | null, suffix?: string | null, postalCode?: string | null, city?: string | null, country?: string | null }> | null };

export type GQLGetExamQueryVariables = Exact<{
  input: GQLExamPeriod;
}>;


export type GQLGetExamQuery = { __typename?: 'Query', ExamDataByPeriod?: Array<{ __typename?: 'CbexintegrationExam', ID: string, examDate?: any | null, availableSeats?: number | null, street?: string | null, houseNumber?: string | null, suffix?: string | null, postalCode?: string | null, city?: string | null, country?: string | null }> | null };

export type GQLGetGoogleMapsAutoCompletePredictionsQueryVariables = Exact<{
  searchName: Scalars['String']['input'];
}>;


export type GQLGetGoogleMapsAutoCompletePredictionsQuery = { __typename?: 'Query', MapsAutoComplete?: { __typename?: 'GoogleMapsAutoCompleteResult', googleSearchResult?: Array<{ __typename?: 'GoogleSearchResult', Description?: string | null, PlaceID?: string | null }> | null } | null };

export type GQLGetProductByNameTypeQueryVariables = Exact<{
  productName: Scalars['String']['input'];
  productType: Scalars['String']['input'];
}>;


export type GQLGetProductByNameTypeQuery = { __typename?: 'Query', WebshopProductByNameType?: { __typename?: 'WebshopProduct', ID: string, name?: string | null, description?: string | null, image?: string | null, price?: any | null, tax?: any | null, type?: string | null, commercialText?: any | null, Options?: any | null } | null };

export type GQLGetProductByCategoryIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GQLGetProductByCategoryIdQuery = { __typename?: 'Query', WebshopProducts?: Array<{ __typename?: 'WebshopProductList', ID: string, name?: string | null, description?: string | null, image?: string | null, price?: any | null, tax?: any | null, type?: string | null, commercialText?: any | null, categoryID?: string | null, Options?: any | null }> | null };


export const AddWebshopOrderDocument = gql`
    mutation AddWebshopOrder($input: WebshopOrderWithOrderLinesParams!) {
  NewWebshopOrder(input: $input) {
    ID
    totalPrice
  }
}
    `;
export type GQLAddWebshopOrderMutationFn = Apollo.MutationFunction<GQLAddWebshopOrderMutation, GQLAddWebshopOrderMutationVariables>;

/**
 * __useAddWebshopOrderMutation__
 *
 * To run a mutation, you first call `useAddWebshopOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWebshopOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWebshopOrderMutation, { data, loading, error }] = useAddWebshopOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddWebshopOrderMutation(baseOptions?: Apollo.MutationHookOptions<GQLAddWebshopOrderMutation, GQLAddWebshopOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLAddWebshopOrderMutation, GQLAddWebshopOrderMutationVariables>(AddWebshopOrderDocument, options);
      }
export type AddWebshopOrderMutationHookResult = ReturnType<typeof useAddWebshopOrderMutation>;
export type AddWebshopOrderMutationResult = Apollo.MutationResult<GQLAddWebshopOrderMutation>;
export type AddWebshopOrderMutationOptions = Apollo.BaseMutationOptions<GQLAddWebshopOrderMutation, GQLAddWebshopOrderMutationVariables>;
export const GetCityListDocument = gql`
    query GetCityList($input: ExamPeriod!) {
  ExamCities(input: $input) {
    city
    enabled
  }
}
    `;

/**
 * __useGetCityListQuery__
 *
 * To run a query within a React component, call `useGetCityListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCityListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCityListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCityListQuery(baseOptions: Apollo.QueryHookOptions<GQLGetCityListQuery, GQLGetCityListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLGetCityListQuery, GQLGetCityListQueryVariables>(GetCityListDocument, options);
      }
export function useGetCityListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLGetCityListQuery, GQLGetCityListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLGetCityListQuery, GQLGetCityListQueryVariables>(GetCityListDocument, options);
        }
export type GetCityListQueryHookResult = ReturnType<typeof useGetCityListQuery>;
export type GetCityListLazyQueryHookResult = ReturnType<typeof useGetCityListLazyQuery>;
export type GetCityListQueryResult = Apollo.QueryResult<GQLGetCityListQuery, GQLGetCityListQueryVariables>;
export const GetExamDataByPlaceIdDocument = gql`
    query GetExamDataByPlaceID($examData: ExamPeriod!, $id: String!) {
  ExamDataByPlaceID(examData: $examData, placeID: $id) {
    ID
    examDate
    availableSeats
    street
    houseNumber
    suffix
    postalCode
    city
    country
  }
}
    `;

/**
 * __useGetExamDataByPlaceIdQuery__
 *
 * To run a query within a React component, call `useGetExamDataByPlaceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExamDataByPlaceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExamDataByPlaceIdQuery({
 *   variables: {
 *      examData: // value for 'examData'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExamDataByPlaceIdQuery(baseOptions: Apollo.QueryHookOptions<GQLGetExamDataByPlaceIdQuery, GQLGetExamDataByPlaceIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLGetExamDataByPlaceIdQuery, GQLGetExamDataByPlaceIdQueryVariables>(GetExamDataByPlaceIdDocument, options);
      }
export function useGetExamDataByPlaceIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLGetExamDataByPlaceIdQuery, GQLGetExamDataByPlaceIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLGetExamDataByPlaceIdQuery, GQLGetExamDataByPlaceIdQueryVariables>(GetExamDataByPlaceIdDocument, options);
        }
export type GetExamDataByPlaceIdQueryHookResult = ReturnType<typeof useGetExamDataByPlaceIdQuery>;
export type GetExamDataByPlaceIdLazyQueryHookResult = ReturnType<typeof useGetExamDataByPlaceIdLazyQuery>;
export type GetExamDataByPlaceIdQueryResult = Apollo.QueryResult<GQLGetExamDataByPlaceIdQuery, GQLGetExamDataByPlaceIdQueryVariables>;
export const GetExamDocument = gql`
    query getExam($input: ExamPeriod!) {
  ExamDataByPeriod(input: $input) {
    ID
    examDate
    availableSeats
    street
    houseNumber
    suffix
    postalCode
    city
    country
  }
}
    `;

/**
 * __useGetExamQuery__
 *
 * To run a query within a React component, call `useGetExamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExamQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetExamQuery(baseOptions: Apollo.QueryHookOptions<GQLGetExamQuery, GQLGetExamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLGetExamQuery, GQLGetExamQueryVariables>(GetExamDocument, options);
      }
export function useGetExamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLGetExamQuery, GQLGetExamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLGetExamQuery, GQLGetExamQueryVariables>(GetExamDocument, options);
        }
export type GetExamQueryHookResult = ReturnType<typeof useGetExamQuery>;
export type GetExamLazyQueryHookResult = ReturnType<typeof useGetExamLazyQuery>;
export type GetExamQueryResult = Apollo.QueryResult<GQLGetExamQuery, GQLGetExamQueryVariables>;
export const GetGoogleMapsAutoCompletePredictionsDocument = gql`
    query GetGoogleMapsAutoCompletePredictions($searchName: String!) {
  MapsAutoComplete(searchName: $searchName) {
    googleSearchResult {
      Description
      PlaceID
    }
  }
}
    `;

/**
 * __useGetGoogleMapsAutoCompletePredictionsQuery__
 *
 * To run a query within a React component, call `useGetGoogleMapsAutoCompletePredictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleMapsAutoCompletePredictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleMapsAutoCompletePredictionsQuery({
 *   variables: {
 *      searchName: // value for 'searchName'
 *   },
 * });
 */
export function useGetGoogleMapsAutoCompletePredictionsQuery(baseOptions: Apollo.QueryHookOptions<GQLGetGoogleMapsAutoCompletePredictionsQuery, GQLGetGoogleMapsAutoCompletePredictionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLGetGoogleMapsAutoCompletePredictionsQuery, GQLGetGoogleMapsAutoCompletePredictionsQueryVariables>(GetGoogleMapsAutoCompletePredictionsDocument, options);
      }
export function useGetGoogleMapsAutoCompletePredictionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLGetGoogleMapsAutoCompletePredictionsQuery, GQLGetGoogleMapsAutoCompletePredictionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLGetGoogleMapsAutoCompletePredictionsQuery, GQLGetGoogleMapsAutoCompletePredictionsQueryVariables>(GetGoogleMapsAutoCompletePredictionsDocument, options);
        }
export type GetGoogleMapsAutoCompletePredictionsQueryHookResult = ReturnType<typeof useGetGoogleMapsAutoCompletePredictionsQuery>;
export type GetGoogleMapsAutoCompletePredictionsLazyQueryHookResult = ReturnType<typeof useGetGoogleMapsAutoCompletePredictionsLazyQuery>;
export type GetGoogleMapsAutoCompletePredictionsQueryResult = Apollo.QueryResult<GQLGetGoogleMapsAutoCompletePredictionsQuery, GQLGetGoogleMapsAutoCompletePredictionsQueryVariables>;
export const GetProductByNameTypeDocument = gql`
    query GetProductByNameType($productName: String!, $productType: String!) {
  WebshopProductByNameType(productName: $productName, productType: $productType) {
    ID
    name
    description
    image
    price
    tax
    type
    commercialText
    Options
  }
}
    `;

/**
 * __useGetProductByNameTypeQuery__
 *
 * To run a query within a React component, call `useGetProductByNameTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductByNameTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductByNameTypeQuery({
 *   variables: {
 *      productName: // value for 'productName'
 *      productType: // value for 'productType'
 *   },
 * });
 */
export function useGetProductByNameTypeQuery(baseOptions: Apollo.QueryHookOptions<GQLGetProductByNameTypeQuery, GQLGetProductByNameTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLGetProductByNameTypeQuery, GQLGetProductByNameTypeQueryVariables>(GetProductByNameTypeDocument, options);
      }
export function useGetProductByNameTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLGetProductByNameTypeQuery, GQLGetProductByNameTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLGetProductByNameTypeQuery, GQLGetProductByNameTypeQueryVariables>(GetProductByNameTypeDocument, options);
        }
export type GetProductByNameTypeQueryHookResult = ReturnType<typeof useGetProductByNameTypeQuery>;
export type GetProductByNameTypeLazyQueryHookResult = ReturnType<typeof useGetProductByNameTypeLazyQuery>;
export type GetProductByNameTypeQueryResult = Apollo.QueryResult<GQLGetProductByNameTypeQuery, GQLGetProductByNameTypeQueryVariables>;
export const GetProductByCategoryIdDocument = gql`
    query GetProductByCategoryID($id: ID!) {
  WebshopProducts(categoryID: $id) {
    ID
    name
    description
    image
    price
    tax
    type
    commercialText
    categoryID
    Options
  }
}
    `;

/**
 * __useGetProductByCategoryIdQuery__
 *
 * To run a query within a React component, call `useGetProductByCategoryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductByCategoryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductByCategoryIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductByCategoryIdQuery(baseOptions: Apollo.QueryHookOptions<GQLGetProductByCategoryIdQuery, GQLGetProductByCategoryIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLGetProductByCategoryIdQuery, GQLGetProductByCategoryIdQueryVariables>(GetProductByCategoryIdDocument, options);
      }
export function useGetProductByCategoryIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLGetProductByCategoryIdQuery, GQLGetProductByCategoryIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLGetProductByCategoryIdQuery, GQLGetProductByCategoryIdQueryVariables>(GetProductByCategoryIdDocument, options);
        }
export type GetProductByCategoryIdQueryHookResult = ReturnType<typeof useGetProductByCategoryIdQuery>;
export type GetProductByCategoryIdLazyQueryHookResult = ReturnType<typeof useGetProductByCategoryIdLazyQuery>;
export type GetProductByCategoryIdQueryResult = Apollo.QueryResult<GQLGetProductByCategoryIdQuery, GQLGetProductByCategoryIdQueryVariables>;