import React from "react";
import {
  Stack,
  Card,
  CardContent,
  Typography,
  Icon,
  CardHeader,
} from "@mui/material";
import { useTheme } from "../../../theme/use-theme";
import { GQLWebshopProductList } from "../../../gql";

interface ProductDetailsCardProps {
  item: GQLWebshopProductList;
}

const ProductDetailsCard = (props: ProductDetailsCardProps) => {
  const { item } = props;
  const theme = useTheme();
  return (
    <Card
      sx={{
        height: 450,
        maxWidth: 300,
        width: 300,
      }}
    >
      <Stack display="flex" flex={0.8}>
        <Stack alignItems="center">
          <img
            style={{
              borderRadius: 3,
              width: "100%",
              minWidth: 172,
              height: 200,
            }}
            alt="Product"
            src={`/${item.image}`}
          />
        </Stack>
        <CardContent sx={{ justifyContent: "space-between" }}>
          <Typography
            fontWeight={theme.fontWeights[6]}
            fontSize={theme.fontSizes[8]}
            color={theme.appColors.tertiaryLight}
            sx={{ height: 50 }}
          >
            {item.description}
          </Typography>

          <Stack height={120} alignItems="flex-start">
            {item.commercialText &&
              item.commercialText.map((itm: any, index: number) => {
                return (
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    key={index}
                    spacing={1}
                  >
                    <Icon
                      sx={{
                        color: itm.decorator_color,
                        // fontSize: "13.33px",
                      }}
                    >
                      {itm.decorator}
                    </Icon>
                    <Typography
                      textAlign="center"
                      fontSize={theme.fontSizes[2]}
                      fontWeight={theme.fontWeights[3]}
                    >
                      {itm.content}
                    </Typography>
                  </Stack>
                );
              })}
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            color={theme.appColors.primary}
            spacing={1}
          >
            <Typography
              fontSize={theme.fontSizes[4]}
              fontWeight={theme.fontWeights[3]}
            >
              Vanaf
            </Typography>

            <Typography
              fontSize={theme.fontSizes[10]}
              fontWeight={theme.fontWeights[5]}
            >
              € {item.price},-
            </Typography>
          </Stack>
        </CardContent>
      </Stack>
    </Card>
  );
};

export default ProductDetailsCard;
