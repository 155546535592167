import React from "react";
import { Stack, Button, Grid } from "@mui/material";

import { useTheme } from "../lib/theme/use-theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import ProgressBar from "../lib/Components/Loading/ProgressBar";
import _ from "lodash";
import ErrorComponent from "../lib/Components/Error/ErrorComponent";
import ProductDetailsCard from "../lib/Components/Card/ProductCard/ProductDetailsCard";
import { useStorage } from "../lib/hooks";
import {
  useGetProductByCategoryIdQuery,
  GQLWebshopProductList,
} from "../lib/gql";
import { useRouter } from "next/router";
import AppContext from "../utils/AppContext";

var HomePage = () => {
  const { localAppState } = React.useContext(AppContext);
  const [activeButtonID, setActiveButtonID] = React.useState(
    localAppState.activeButtonID
  );
  const router = useRouter();

  const { getItem, setItem } = useStorage();

  // const token = getItem("ShoppingBasketList");
  const [filteredProductList, setFilteredProductList] =
    React.useState<GQLWebshopProductList[]>();
  const theme = useTheme();
  const {
    loading,
    error,
    data: ProductData,
  } = useGetProductByCategoryIdQuery({
    variables: {
      id: "1",
    },
    fetchPolicy: "network-only",
  });
  const smallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const data =
    ProductData &&
    ProductData.WebshopProducts &&
    ProductData.WebshopProducts.length > 0 &&
    ProductData.WebshopProducts;

  React.useEffect(() => {
    if (localAppState && localAppState.activeButtonID) {
      setActiveButtonID(localAppState.activeButtonID);
    }
  }, [localAppState, localAppState.activeButtonID]);
  React.useEffect(() => {
    if (
      ProductData &&
      ProductData?.WebshopProducts &&
      ProductData.WebshopProducts.length > 0
    ) {
      if (activeButtonID === 1) {
        setFilteredProductList(ProductData.WebshopProducts);
      } else {
        const temp = ProductData.WebshopProducts.filter((item: any) => {
          return item.categoryID === activeButtonID;
        });
        setFilteredProductList(temp);
      }
    }
  }, [activeButtonID, ProductData, ProductData?.WebshopProducts]);

  return (
    <Stack
      id="homePageStack"
      direction="column"
      mb={2}
      // maxWidth={1100}
      style={{ marginTop: theme.dimensions.heightAppBar + 10 }}
    >
      <Stack mt={3}>{loading && <ProgressBar loading={loading} />}</Stack>
      {!loading && filteredProductList && filteredProductList.length > 0 && (
        <Grid
          id="filteredProductListGrid"
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowSpacing={2}
          maxWidth={1200}
        >
          {filteredProductList.map(
            (item: GQLWebshopProductList, index: number) => (
              <Grid
                id="ProductListGridItem"
                alignItems="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                paddingRight={2}
                paddingLeft={2}
                item
                key={index}
              >
                <ProductDetailsCard item={item} />
                <Stack
                  sx={{
                    position: "relative",
                    top: -20,
                    justifyContent: "flex-end",
                    maxWidth: 247,
                  }}
                >
                  <Button
                    onClick={(e: any) => {
                      e.preventDefault();

                      //@ts-ignore
                      setItem(item.name, JSON.stringify(item));
                      router.push({
                        pathname: "/OrderPage",
                        query: { id: item.name, type: item.type },
                      });
                      // }
                    }}
                    sx={{
                      ":hover": {
                        backgroundColor: theme.appColors.tertiaryLight,
                        color: theme.appColors.white,
                      },
                      bgcolor: theme.appColors.primary,
                      color: theme.appColors.white,

                      width: 107,

                      zIndex: 10,
                    }}
                  >
                    Bestellen
                  </Button>
                </Stack>
              </Grid>
            )
          )}
        </Grid>
      )}

      {error && <ErrorComponent errorObject={error} />}
    </Stack>
  );
};

export default HomePage;
