/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable func-style */
import React from "react";
import {Box, Typography, Stack,Paper} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useTheme } from "../../theme/use-theme";
/* eslint-disable-next-line */
export interface ErrorAlertProps {
  errorObject: any;
}

export var ErrorComponent = (props: ErrorAlertProps) => {
  const { errorObject } = props;
  const theme = useTheme();
  return (
    
      <Stack  ml={2} mr={2} display="flex" justifyContent="center" alignItems="center">
        <Stack mx={2}  spacing={1} alignItems="center">
          <ErrorOutlineIcon color="error" />
          <Typography color={theme.appColors.error}>An Error Occured</Typography>
          <Typography color={theme.appColors.error}>{errorObject.message}</Typography>
        </Stack>
      </Stack>
    
  );
}

export default ErrorComponent;
